import * as Sentry from '@sentry/browser'

import config from '../config'

export default {
  loaded: false,
  initialized: false,
  init() {
    Sentry.init({
      dsn: config.sentryDsn,
      environment: window.gcEnv,
      ignoreErrors: ["Cannot read properties of null (reading 'postMessage')", "Can't find variable: gmo"],
    })
    this.initialized = true
  },
  error(error, context) {
    this.log(error, context, 'error')
  },
  warning(error, context) {
    this.log(error, context, 'warning')
  },
  log(error, context = {}, level) {
    if (this.initialized) {
      Sentry.withScope(scope => {
        scope.setTags(context.tags)
        scope.setTag('codebase', 'marketing-site')
        scope.setExtras(context.extras)
        scope.setLevel(level)
        typeof error === 'string'
          ? Sentry.captureMessage(error)
          : Sentry.captureException(error)
      })
    } else {
      // eslint-disable-next-line no-console
      console.error(level, error, context)
    }
  },
}
